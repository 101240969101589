import "minireset.css"
import "normalize.css"
import * as Styled from "./styles"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Header } from "../header"
import React from "react"
import { ThemeProvider } from "styled-components"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import MailIcon from "@material-ui/icons/Mail"
import { useWindowSize } from "../../utils"
import bannerImage from "./../../images/bg.jpg"

import HomeIcon from "@material-ui/icons/Home"
import RoomServiceIcon from "@material-ui/icons/RoomService"
import ArtTrackIcon from "@material-ui/icons/ArtTrack"
import FaceIcon from "@material-ui/icons/Face"
import ContactMailIcon from "@material-ui/icons/ContactMail"

import { Loader } from "./../loader"
import { Helmet } from "react-helmet"

export interface LayoutProps {
  title?: string
  logo?: string
}
const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    appBar: {
      backgroundColor: "rgba(25, 25, 35, 0.99)",
      // background:
      // "linear-gradient( 159deg,rgba(37,37,50,0.98) 0%,rgba(35,35,45,0.98) 100% )",
      color: "white",
      boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.9)",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(60% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    appBarShiftTwo: {
      width: `calc(60% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth - 40,
      background:
        "linear-gradient(159deg, rgba(37, 37, 50, 0.98) 0%, rgba(35, 35, 45, 0.98) 100%)",
      boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.9)",
      color: "white",
    },
    drawerPaperTwo: {
      width: drawerWidth,
      background:
        "linear-gradient(159deg, rgba(37, 37, 50, 0.98) 0%, rgba(35, 35, 45, 0.98) 100%)",
      boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.9)",
      color: "white",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    drawerHeaderTwo: {
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      width: "100%",
      padding: theme.spacing(3),
    },
  })
)

const Layout: React.FC<LayoutProps> = ({ children, title, logo }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          logo
        }
      }
    }
  `)


  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)

  const { width, height } = useWindowSize()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleDrawerOpen2 = () => {
    setOpen2(true)
  }

  const handleDrawerClose2 = () => {
    setOpen2(false)
  }

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     try {
  //       const element = document.getElementById("loader")
  //       if (element) element.className = "remove"
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }, 3000)
  //   setTimeout(() => {
  //     try {
  //       const element = document.getElementById("loader-container")
  //       if (element) element.parentNode.removeChild(element)
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }, 4000)
  // })

  return (
    <ThemeProvider theme={Styled.theme}>
      <Styled.GlobalStyle />
      <Helmet>
        <script
          data-ad-client="ca-pub-2034139152221564"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      </Helmet>
      <Grid container>
        {width > 768 && (
          <Grid item md={3} lg={2} xl={2}>
            <Header
              siteTitle={data.site.siteMetadata?.title || title}
              logo={data.site.siteMetadata?.logo || logo}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={10}
          xl={10}
          style={{
            overflowY: "scroll",
            height: height,
          }}
        >
          <Styled.Layout>
            <Styled.Content>
              <Styled.Body>
                <Styled.MainContent>
                  <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                      style={{
                        width: width > 768 ? "60%" : "100%",
                      }}
                      position="fixed"
                      className={clsx(classes.appBar)}
                    >
                      <Toolbar>
                        {width <= 768 && (
                          <IconButton
                            id="animated-left"
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerOpen2}
                            className={clsx(open2)}
                          >
                            <ChevronRightIcon style={{ color: "white" }} />
                          </IconButton>
                        )}
                        <Typography
                          variant="h6"
                          noWrap
                          style={{
                            textAlign: width <= 768 ? "center" : "left",
                            fontSize:
                              width > 2100
                                ? "20px"
                                : width > 1024
                                ? "18px"
                                : "12px",
                          }}
                          className={classes.title}
                        >
                          {title}
                        </Typography>
                        <IconButton
                          id="animated-right"
                          color="inherit"
                          aria-label="open drawer"
                          edge="end"
                          onClick={handleDrawerOpen}
                          className={clsx(open)}
                        >
                          <ChevronLeftIcon style={{ color: "white" }} />
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                    <main
                      className={classes.content}
                      style={{ padding: width > 768 ? "20px" : "0px" }}
                    >
                      <div className={classes.drawerHeader}></div>
                      {children}
                    </main>
                    <Drawer
                      className={classes.drawer}
                      variant="persistent"
                      anchor="left"
                      open={open2}
                      classes={{
                        paper: classes.drawerPaperTwo,
                      }}
                    >
                      <div className={classes.drawerHeaderTwo}>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          edge="end"
                          onClick={handleDrawerClose2}
                          className={clsx(open2)}
                        >
                          <ChevronLeftIcon style={{ color: "white" }} />
                        </IconButton>
                      </div>
                      <Divider
                        style={{ marginTop: width <= 768 ? "-40px" : "0" }}
                      />
                      <Header
                        siteTitle={data.site.siteMetadata?.title || title}
                        logo={data.site.siteMetadata?.logo || logo}
                      />
                      
                      <Divider />
                    </Drawer>
                    <Drawer
                      className={classes.drawer}
                      variant="persistent"
                      anchor="right"
                      open={open}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                      <div className={classes.drawerHeader}>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          edge="end"
                          onClick={handleDrawerClose}
                          className={clsx(open)}
                        >
                          <ChevronRightIcon style={{ color: "white" }} />
                        </IconButton>
                      </div>
                      <Divider />
                      <List
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          height: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <Link
                          to="/"
                          style={{ color: "white", textDecoration: "none", width: "100%" }}
                        >
                          <ListItem button key={"Home"}>
                            <ListItemIcon>
                              <HomeIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Home"} />
                          </ListItem>
                        </Link>
                        <Link
                          to="/services"
                          style={{ color: "white", textDecoration: "none", width: "100%"  }}
                        >
                          <ListItem button key={"Services"}>
                            <ListItemIcon>
                              <RoomServiceIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Services"} />
                          </ListItem>
                        </Link>
                        <Link
                          to="/portfolio"
                          style={{ color: "white", textDecoration: "none", width: "100%"  }}
                        >
                          <ListItem button key={"Portfolio"}>
                            <ListItemIcon>
                              <ArtTrackIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Portfolio"} />
                          </ListItem>
                        </Link>
                        <Link
                          to="/my-story"
                          style={{ color: "white", textDecoration: "none", width: "100%"  }}
                        >
                          <ListItem button key={"My story"}>
                            <ListItemIcon>
                              <FaceIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"My story"} />
                          </ListItem>
                        </Link>
                        <Link
                          to="/contact"
                          style={{ color: "white", textDecoration: "none", width: "100%"  }}
                        >
                          <ListItem button key={"Contact"}>
                            <ListItemIcon>
                              <ContactMailIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Contact"} />
                          </ListItem>
                        </Link>
                      </List>
                      <Divider />
                    </Drawer>
                  </div>

                  <Styled.Footer
                    style={{
                      margin: `0rem`,
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        style={{
                          fontSize:
                            width > 2100
                              ? "20px"
                              : width > 1024
                              ? "18px"
                              : "12px",
                        }}
                      >
                        © {new Date().getFullYear()}, Muhammad Jazzel Mehmood.
                      </Grid>
                      {width > 425 && (
                        <Grid item>
                          <Link
                            style={{
                              color: "white",
                              fontSize:
                                width > 2100
                                  ? "20px"
                                  : width > 1024
                                  ? "18px"
                                  : "12px",
                            }}
                            to="https://github.com/Jazz-hash"
                          >
                            Author: Muhammad Jazzel Mehmood
                          </Link>
                        </Grid>
                      )}
                    </Grid>
                  </Styled.Footer>
                </Styled.MainContent>
              </Styled.Body>
              {/* <Styled.Navbar>
                <Styled.NavIcon>
                  <Button
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(classes.menuButton, open && classes.hide)}
                  >
                    <MenuIcon style={{ fontSize: "40px" }} />
                  </Button>
                </Styled.NavIcon>
                <Box
                  mt={2}
                  style={{ height: 500, backgroundColor: "yellow" }}
                ></Box>
              </Styled.Navbar> */}
            </Styled.Content>
          </Styled.Layout>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default Layout
