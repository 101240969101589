import { Button, Menu } from "@material-ui/core"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Image } from "../../image"
import styled from "styled-components"
import { useWindowSize } from "../../../utils"

export const SignupButton = styled(Button)`
  width: 100%;
`

export const MobileMenu = styled(Menu)`
  top: 55px !important;
  z-index: 4;

  .MuiMenu-paper,
  .MuiMenu-list {
    background: ${({ theme }) => theme.colors.jelly} !important;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
  }

  .MuiMenu-paper {
    left: 0 !important;
  }
`

export const SiteHeaderBody = styled.div`
  padding: 0 1.0875rem 1.45rem;

  @media screen and (min-width: 0) {
    padding: 0 1.0875rem 0rem;
  }

  @media screen and (min-width: 769px) {
    padding: 0 1.0875rem 0rem;
  }
`

export const SiteContext = styled.div`
  // align-items: inherit;
  // display: flex;

  @media screen and (min-width: 1025px) {
    padding: 0;
  }

  @media screen and (min-width: 2100px) {
    padding: 2rem 1.0875rem 1.45rem;
  }
`

export const SiteHeader = styled.header`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
`

export const SiteLink = styled(AnchorLink)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  text-decoration: none;
`

export const SiteTitle = styled(SiteLink)`
  font-size: 1.5rem;
`

export const SiteLogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 1.45rem 1.0875rem;
  font-size: 18px;

  @media screen and (min-width: 769px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1025px) {
    font-size: 20px;
  }

  @media screen and (min-width: 2100px) {
    font-size: 30px;
  }
`

export const SiteLogo = styled(Image)`
  width: 120px !important;
  height: 120px !important;
`
export const SmallText = styled.small`
  margin-top: -10px;
  font-size: 12px;
  margin-bottom: 10px;

  @media screen and (min-width: 769px) {
    font-size: 12px;
  }

  @media screen and (min-width: 1025px) {
    font-size: 14px;
  }
  @media screen and (min-width: 2100px) {
    font-size: 20px;
  }
`

export const ContextItem = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-top: -5px;

  @media screen and (min-width: 2100px) {
    font-size: 19px;
  }
`

export const ContextItemAnswer = styled.p`
  font-size: 13px;
  text-align: right;
  margin-top: -5px;
  @media screen and (min-width: 1440px) {
    font-size: 18px;
  }
`
export const HR = styled.hr`
  background-color: white;

  @media screen and (min-width: 0px) {
    margin: 5px;
  }

  @media screen and (min-width: 769px) {
    margin: 8px;
  }
`

export const Language = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;

  @media screen and (min-width: 769px) {
    font-size: 10px;
    margin-top: 12px;
  }

  @media screen and (min-width: 1025px) {
    font-size: 12px;
    margin-top: 16px;
  }

  @media screen and (min-width: 2100px) {
    font-size: 18px;
    margin-top: 20px;
  }
`
export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const LanguageTwo = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin-top: 8px;

  @media screen and (min-width: 769px) {
    font-size: 11px;
    margin-top: 12px;
  }

  @media screen and (min-width: 1025px) {
    font-size: 13px;
  }

  @media screen and (min-width: 2100px) {
    font-size: 18px;
  }
`

export const Break = styled.div`
  margin: 4px;
`
export const Item = styled.p`
  margin-left: 2px;
  font-size: 14px;
`
export const SiteHeadFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  padding: 0 0 20px 0;
`
