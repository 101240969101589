import * as React from "react"
import * as Styled from "./styles"
import { Box, Button, Grid, LinearProgress } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { useWindowSize } from "../../utils"
import { CircularProgressWithLabel } from "../circular-progress"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import FacebookIcon from "@material-ui/icons/Facebook"
import GitHubIcon from "@material-ui/icons/GitHub"
import TwitterIcon from "@material-ui/icons/Twitter"
import InstagramIcon from "@material-ui/icons/Instagram"
import { graphql, useStaticQuery } from "gatsby"

export interface HeaderProps {
  siteTitle: string
  logo?: string
}

const Header: React.FC<HeaderProps> = ({ siteTitle }) => {
  const { width, height } = useWindowSize()

  const {
    information: { edges },
  } = useStaticQuery(
    graphql`
      query MyPersonalInformationQuery {
        information: allContentfulPersonalInformation {
          edges {
            node {
              name
              country
              city
              street
              age
              emails {
                development
                work
                school
              }
              languages {
                skills {
                  Backend
                  Frontend
                  CloudStack
                }
              }
              phoneNumbers {
                numbers
              }
              socials {
                Facebook
                Instagram
                GitHub
                LinkedIn
                Twitter
                WhatsApp
              }
              avatar {
                file {
                  url
                }
              }
              cvUri
              skills {
                skills
              }
            }
          }
        }
      }
    `
  )

  const { node } = edges[0]
  const avatar = `https:${node.avatar.file.url}`
  const skills = node.skills.skills.slice(0, 2)
  return (
    <div style={{ height: height }}>
      <Styled.SiteHeader>
        <Styled.SiteLogoContainer>
          {avatar && (
            <img
              src={avatar}
              style={{ height: "120px", width: "120px", borderRadius: "50%" }}
              alt="user"
            />
          )}
          <p className="styled-header-font">{siteTitle}</p>
          <Styled.SmallText className="styled-header-font">
            Full Stack developer
          </Styled.SmallText>
        </Styled.SiteLogoContainer>
        <Styled.SiteHeaderBody

        // data-sal="slide-up"
        // data-sal-delay="300"
        // data-sal-easing="ease"
        >
          {width > 2100 && (
            <>
              <Styled.SiteContext>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Styled.ContextItem>Residence:</Styled.ContextItem>
                    <Styled.ContextItem>City:</Styled.ContextItem>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Styled.ContextItemAnswer>
                      {node.country}
                    </Styled.ContextItemAnswer>
                    <Styled.ContextItemAnswer>
                      {node.city}
                    </Styled.ContextItemAnswer>
                  </Grid>
                </Grid>
              </Styled.SiteContext>
              <Styled.HR />
            </>
          )}
          <Styled.SiteContext>
            <Box>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Styled.Flex>
                    <CircularProgressWithLabel
                      value={node.languages.skills.Frontend}
                      size={width > 2100 ? 90 : width > 1024 ? 60 : 50}
                    />
                    <Styled.Language>Frontend</Styled.Language>
                  </Styled.Flex>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Styled.Flex>
                    <CircularProgressWithLabel
                      value={node.languages.skills.Backend}
                      size={width > 2100 ? 90 : width > 1024 ? 60 : 50}
                    />
                    <Styled.Language>Backend</Styled.Language>
                  </Styled.Flex>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Styled.Flex>
                    <CircularProgressWithLabel
                      value={node.languages.skills.CloudStack}
                      size={width > 2100 ? 90 : width > 1024 ? 60 : 50}
                    />

                    <Styled.Language>CloudStack</Styled.Language>
                  </Styled.Flex>
                </Grid>
              </Grid>
            </Box>
          </Styled.SiteContext>
          <Styled.HR />
          <Styled.SiteContext>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="stretch"
            >
              {skills.map((skill, index) => (
                <div key={index}>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Styled.LanguageTwo>{skill}</Styled.LanguageTwo>
                      </Grid>
                      <Grid
                        item
                        style={{ fontSize: width > 2100 ? "18px" : "12px" }}
                      >
                        {skill === "Web Designing" ? "95%" : "89%"}
                      </Grid>
                    </Grid>

                    <LinearProgress
                      variant="determinate"
                      value={skill === "Web Designing" ? 95 : 89}
                      color="primary"
                    />
                  </Grid>
                  <Styled.Break />
                </div>
              ))}
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Styled.LanguageTwo>Mobile Applications</Styled.LanguageTwo>
                  </Grid>
                  <Grid
                    item
                    style={{ fontSize: width > 2100 ? "18px" : "12px" }}
                  >
                    75%
                  </Grid>
                </Grid>
                <LinearProgress
                  variant="determinate"
                  value={75}
                  color="primary"
                />
              </Grid>
              <Styled.Break />
            </Grid>
          </Styled.SiteContext>
          <Styled.HR />
          {/* <Styled.SiteContext>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <CheckIcon style={{ color: "yellow", fontSize: "20px" }} />
                </Grid>
                <Grid item>
                  <Styled.Item>Bootstrap, Materialize</Styled.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <CheckIcon style={{ color: "yellow", fontSize: "20px" }} />
                </Grid>
                <Grid item>
                  <Styled.Item>Bootstrap, Materialize</Styled.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <CheckIcon
                    style={{
                      color: "yellow",
                      fontSize: "20px",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Styled.Item>Bootstrap, Materialize</Styled.Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <CheckIcon style={{ color: "yellow", fontSize: "20px" }} />
                </Grid>
                <Grid item>
                  <Styled.Item>Bootstrap, Materialize</Styled.Item>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Styled.SiteContext>
        <Styled.HR />*/}
          <Styled.SiteContext>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  color: "white",
                  fontSize: width > 2100 ? "20px" : "12px",
                }}
                onClick={() => {
                  window.location.href = node.cvUri
                }}
              >
                Download CV &nbsp; &nbsp;
                <FontAwesomeIcon color="white" icon={faDownload} />
              </Button>
            </div>
          </Styled.SiteContext>
        </Styled.SiteHeaderBody>
        <br />

        <Styled.SiteHeadFooter>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item>
              <a href={node.socials.Facebook}>
                <FacebookIcon
                  style={{
                    color: "white",
                    fontSize: width > 2100 ? "40px" : "25px",
                  }}
                />
              </a>
            </Grid>
            <Grid item>
              <a href={node.socials.WhatsApp}>
                <WhatsAppIcon
                  style={{
                    color: "white",
                    fontSize: width > 2100 ? "40px" : "25px",
                  }}
                />
              </a>
            </Grid>
            <Grid item>
              <a href={node.socials.GitHub}>
                <GitHubIcon
                  style={{
                    color: "white",
                    fontSize: width > 2100 ? "40px" : "25px",
                  }}
                />
              </a>
            </Grid>
            <Grid item>
              <a href={node.socials.Instagram}>
                <InstagramIcon
                  style={{
                    color: "white",
                    fontSize: width > 2100 ? "40px" : "25px",
                  }}
                />
              </a>
            </Grid>
            <Grid item>
              <a href={node.socials.Twitter}>
                <TwitterIcon
                  style={{
                    color: "white",
                    fontSize: width > 2100 ? "40px" : "25px",
                  }}
                />
              </a>
            </Grid>
          </Grid>
        </Styled.SiteHeadFooter>

        {/* {!width ? null : width <= 1350 ? (
				<>
					<Button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
						<FontAwesomeIcon color="white" icon={faBars} />
						{mobileMenu}<
					</Button>
				</>
			) : (
				menu
			)} */}
      </Styled.SiteHeader>
    </div>
  )
}

export default Header
