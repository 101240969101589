import styled, { DefaultTheme, createGlobalStyle } from "styled-components"
import globalCSS from "./modules"
import dripMask from "../../../images/drip.svg"
import bannerImage from "./../../../images/bg.jpg"

export const theme: DefaultTheme = {
  colors: {
    black: "#000000",
    blue: "#332379",
    darkBlue: "#10152d",
    jelly: "#4e2378",
    mediumGray: "#383838",
    peanutButter: "#fff0cc",
    primaryBlue: "#077cf9",
    success: "#008000",
    warning: "#ff0000",
    white: "#ffffff",
  },
}

export const GlobalStyle = createGlobalStyle`
	${globalCSS}

	@import url('https://fonts.googleapis.com/css2?family=Acme&family=Courier+Prime&family=Noto+Sans+JP:wght@500&family=Odibee+Sans&family=Poppins:wght@900&family=Roboto+Mono&display=swap');

    * {
        font-family: 'Roboto Mono', monospace !important;
    }
    .styled-font {
        font-family: 'Poppins', sans-serif !important;
    }
    .styled-font-two {
font-family: 'Courier Prime', monospace !important;
    }
    .styled-header-font {
      font-family: 'Noto Sans JP', sans-serif;
    }
    .MuiLinearProgress-barColorPrimary {
    background-color: rgb(255, 193, 7) !important;
}

.remove {
	// background-color:red !important;
	height: 0 !important;
	z-index: 0;
	transition: 1s ease height;
}

	body {
		background: linear-gradient(
			159deg,
			rgba(37, 37, 50, 0.98) 0%,
			rgba(35, 35, 45, 0.98) 100%
		);
	}
	.fluid {
		width: 100%;
	}

	.MuiButton-root.MuiButton-containedPrimary {
		background-color: ${({ theme }) => theme.colors.primaryBlue};
	}

	.MuiMenu-list {
		background-color: ${({ theme }) => theme.colors.jelly};
	}

	.MuiButton-label > * {
		margin: 0;
	}

	.Mui-disabled {
		opacity: 0.5;
	}
	/* I got my inspiration from Wes Bos. Check it out here: https://mastergatsby.com/ */
	.drip {
		display: block;
		left: 0;
		width: 100%;
		height: 180px;
		mask: url(${dripMask});
		mask-size: 250px auto;
		mask-repeat: repeat-x;
		position: absolute;
		background: inherit;
		background-position: 0 100%;
		z-index: 1;
	}

	.dripTop {
		bottom: 100%;
		bottom: calc(100% - 1px);
		transform: rotateX(180deg);
	}

	.dripBottom {
		top: calc(100% - 1px);
	}

	.largeItem {
		width: 50%;
	}

	.dot1 {
		position:absolute;
 animation: mymove 3s infinite;
 
	}
	.dot2 {
		left: 190px;

		position:absolute;
 animation: mymove 3s infinite;
 animation-delay: 0.1s;
	}
	.dot3 {
		left: 210px;

		position:absolute;
 animation: mymove 3s infinite;
	}
	.dot4 {
		left: 230px;

		position:absolute;
 animation: mymove 3s ease infinite;
 animation-delay: 0.1s;
	}

	
	@media screen and (max-width: 425px){
		.dot2 {
			left: 165px;
		}
		.dot3 {
			left: 180px;
		}
		.dot4 {
			left: 195px;
		}

	}


	@keyframes mymove {
		0% {top: 0px;}
		10% {top:10px;}
		20% {top: 0px;}
		30% {top: -10px;}
		40% {top: 0px;}
		50% {top: 10px;}
		60% {top:0px;}
		70% {top: -10px;}
		80% {top: 0px;}
		90% {top:10px;}
		100% {top:0px;}
	}


	#animated-left {
		position:absolute;
		left:0;
		animation: left 2s infinite;
	}

	@keyframes left {
		0% {margin-left:0}
		50% {margin-left:5px}
		100% {margin-left:0}
	}

	#animated-right {
		position:absolute;
		right:0;
		animation: right 2s infinite;

	}
	@keyframes right {
		0% {margin-right:0}
		50% {margin-right:5px}
		100% {margin-right:0}
	}

	@media all and (max-width: 700px) {
		.largeItem {
			width: 100%;
		}
	}

	@media all and (min-width: 700px) and (max-width: 950px) {
		.largeItem {
			width: 80%;
		}
	}

	@media all and (min-width: 950px) and (max-width: 1200px) {
		.largeItem {
			width: 65%;
		}
	}

	@media only screen and (max-width: 480px) {
		html {
			font-size: 100%;
		}
	}
`

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(
    180deg,
    rgba(32, 32, 42, 0.93) 0%,
    rgba(30, 30, 40, 0.96) 70%,
    rgba(30, 30, 40, 0.99) 80%,
    #1e1e28 100%
  );
`

export const Layout = styled.div`
  position: relative;
  background-image: url(${bannerImage});
  height: 400px;
  background-position: center;
  background-size: cover;

  footer {
    color: ${({ theme }) => theme.colors.white};

    a {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`
export const Body = styled.div`
  height: 100%;
  width: 100%;
  color: white;
`

export const MainContent = styled.div`
  padding: 10px 10px 0px 10px;
`

export const Footer = styled.div`
  background-color: rgba(15, 15, 25, 0.99);
  box-shadow: 0 3px 8px 0 rgba(15, 15, 20, 0.9);
  padding: 20px;
`
export const NavIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`
