import React from "react"
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { useWindowSize } from "../../utils"

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number; size: number }
) => {
  const { width } = useWindowSize()
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        style={{ color: "rgb(255, 193, 7)" }}
        variant="determinate"
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          style={{
            fontSize: width > 2100 ? "20px" : width > 1024 ? "12px" : "10px",
          }}
          component="div"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default CircularProgressWithLabel
